import React, { useEffect, useState } from "react";
import "./ProductDetails.css";
import { useSelector, useDispatch } from "react-redux";
import {
  clearErrors,
  getProductDetails,
  newReview,
} from "../../actions/productAction";
import { useAlert } from "react-alert";
import { Link, useNavigate, useParams } from "react-router-dom";
import ReviewCard from "./ReviewCard.js";
import Loader from "../layout/Loader/Loader";
import MetaData from "../layout/MetaData";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import InstagramIcon from "@mui/icons-material/Instagram";
import PinterestIcon from "@mui/icons-material/Pinterest";
import WhatsappOutlinedIcon from "@mui/icons-material/WhatsappOutlined";
import RateReviewOutlinedIcon from "@mui/icons-material/RateReviewOutlined";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { addItemsToCart } from "../../actions/cartAction";
import { addItemsToWish } from "../../actions/wishAction";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Rating,
} from "@mui/material";
import { NEW_PRODUCT_RESET } from "../../constants/productConstants";
import { getWeekSaleDis } from "../../actions/weekSaleDisAction";
import ProductCardRecent from "../ProductCard/ProductCardRecent";
import { getProduct } from "../../actions/productAction";
import ProductCardHome from "../ProductCard/ProductCardHome";
import EditIcon from "@mui/icons-material/Edit";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  FacebookShareButton,
  PinterestShareButton,
  WhatsappShareButton,
} from "react-share";

const ProductDetails = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const alert = useAlert();
  const navigate = useNavigate();

  const { recentItems } = useSelector((state) => state.recent);
  const { product, loading, error } = useSelector(
    (state) => state.productDetails
  );

  const shareLink = "https://wocadesigns.in/product/6385d6f4c4101347e52aed1a";

  const { success, error: reviewError } = useSelector(
    (state) => state.newReview
  );
  const { weekSaleDis } = useSelector((state) => state.weekSaleDiss);
  let disWeekSale = weekSaleDis.map((c) => c.weekSaleDis);
  disWeekSale = disWeekSale[0];
  const { products } = useSelector((state) => state.products);

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (reviewError) {
      alert.error(reviewError);
      dispatch(clearErrors());
    }
    if (success) {
      alert.success("Review Submitted Successfully");
      dispatch({ type: NEW_PRODUCT_RESET });
    }
    dispatch(getWeekSaleDis());
    dispatch(getProductDetails(params.id));
    dispatch(getProduct());
  }, [dispatch, params.id, error, alert, reviewError, success]);

  const imgUrl = null;

  // const productPrice = Number(
  //   `${
  //     props.discount
  //       ? props.discount
  //       : product.weekSale
  //       ? disWeekSale
  //       : product.discount
  //   }` > 0
  //     ? `${
  //         product.price -
  //         (product.price / 100) *
  //           `${
  //             props.discount
  //               ? props.discount
  //               : product.weekSale
  //               ? disWeekSale
  //               : product.discount
  //           }`
  //       }`
  //     : `${product.price}`
  //
  // );
  const similarProduct = products.filter(
    (c) =>
      c._id !== product._id && c.stock > 0 && c.category === product.category
  );
  const RecentProduct = recentItems.filter((c) => c.product !== product._id);
  // let freqProductOne = products.filter(
  //   (c) =>
  //     c.category === product.frequentProductOne &&
  //     c._id !== product._id &&
  //     c.stock > 0 &&
  //     c.weekSale !== true
  // );
  // freqProductOne =
  //   freqProductOne[Math.floor(Math.random() * freqProductOne.length)];
  // let freqProductOnePrice = freqProductOne
  //   ? `${
  //       props.discount
  //         ? props.discount
  //         : freqProductOne.weekSale
  //         ? disWeekSale
  //         : freqProductOne.discount
  //     }` > 0
  //     ? `${
  //         freqProductOne.price -
  //         (freqProductOne.price / 100) *
  //           `${
  //             props.discount
  //               ? props.discount
  //               : freqProductOne.weekSale
  //               ? disWeekSale
  //               : freqProductOne.discount
  //           }`
  //       }`
  //     : `${freqProductOne.price}`
  //   : "";
  // freqProductOnePrice = Number(freqProductOnePrice);

  // let freqProductTwo = products.filter(
  //   (c) =>
  //     c.category === product.frequentProductTwo &&
  //     c._id !== freqProductOne._id &&
  //     c._id !== product._id &&
  //     c.stock > 0 &&
  //     c.weekSale !== true
  // );
  // freqProductTwo =
  //   freqProductTwo[Math.floor(Math.random() * freqProductTwo.length)];

  // let freqProductTwoPrice = freqProductTwo
  //   ? `${
  //       props.discount
  //         ? props.discount
  //         : freqProductTwo.weekSale
  //         ? disWeekSale
  //         : freqProductTwo.discount
  //     }` > 0
  //     ? `${
  //         freqProductTwo.price -
  //         (freqProductTwo.price / 100) *
  //           `${
  //             props.discount
  //               ? props.discount
  //               : freqProductTwo.weekSale
  //               ? disWeekSale
  //               : freqProductTwo.discount
  //           }`
  //       }`
  //     : `${freqProductTwo.price}`
  //   : "";
  // freqProductTwoPrice = Number(freqProductTwoPrice);
  // const comboPrice = Math.floor(
  //   productPrice + freqProductOnePrice + freqProductTwoPrice
  // );

  const options = {
    size: "large",
    value: product.ratings,
    readOnly: true,
    precision: 0.5,
  };

  const [quantity, setQuantity] = useState(1);
  const [open, setOpen] = useState(false);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");

  const submitReviewToggle = () => {
    open ? setOpen(false) : setOpen(true);
  };

  const reviewSubmitHandler = () => {
    const myForm = new FormData();
    myForm.set("rating", rating);
    myForm.set("comment", comment);
    myForm.set("productId", params.id);

    dispatch(newReview(myForm));
    setOpen(false);
  };

  const increaseQuantity = () => {
    if (product.stock <= quantity) return;
    const qty = quantity + 1;
    setQuantity(qty);
  };
  const decreaseQuantity = () => {
    if (1 >= quantity) return;
    const qty = quantity - 1;
    setQuantity(qty);
  };

  const addToCartHandler = () => {
    dispatch(addItemsToCart(params.id, quantity));
    alert.success("Item Added To Cart");
  };
  const buyNowCartHandler = () => {
    dispatch(addItemsToCart(params.id, quantity));
    alert.success("Item Added To Cart");
    navigate("/cart");
  };

  const addToWishHandler = () => {
    dispatch(addItemsToWish(product._id));
    alert.success("Item Added To Wishlist");
  };
  const [mainImage, setMainImage] = useState("");
  useEffect(() => {
    if (product.images && product.images.length > 0) {
      setMainImage(product.images[0].url);
    }
  }, [product.images]);
  const handleImageClick = (url) => {
    setMainImage(url);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <MetaData title={`${product.name} -- Woca Designs`} />
          {/* new designs */}
          <div className="card_wrapper">
            <div className="pcard">
              <div className="product-image-display">
                {/* Main Image Display */}
                <div className="main-image-container">
                  {mainImage ? (
                    <img
                      className="main-image"
                      src={mainImage}
                      alt="Main Product"
                    />
                  ) : (
                    <div>
                      <Loader />
                    </div>
                  )}
                </div>

                {/* Thumbnail Image List */}
                <div className="thumbnail-container">
                  {product.images &&
                    product.images.map((item, i) => (
                      <img
                        key={i}
                        className={`thumbnail ${
                          mainImage === item.url ? "active" : ""
                        }`}
                        src={item.url}
                        alt={`Thumbnail ${i}`}
                        onClick={() => handleImageClick(item.url)}
                      />
                    ))}
                </div>
              </div>
              {/* cardleft */}
              {/* <div className="scroll">
                {product.images ? (
                  // <Carousel>
                  product.images &&
                  product.images.map((item, i) => (
                    <img
                      className="CarouselImage"
                      key={i}
                      src={item.url}
                      alt={`${i} Slide`}
                    />
                  ))
                ) : (
                  // </Carousel>
                  <Loader />
                )}
              </div> */}
              {/* cardright */}
              <div className="product_content">
                <h2 className="product_title">{product.name}</h2>
                <Link
                  className="product_link"
                  to={`/products/${product.category}`}
                >
                  {product.category}
                </Link>
                <div className="product_rating">
                  <Rating {...options} />
                  <span>
                    {product.numOfReviews > 0
                      ? `(${product.numOfReviews} Review)`
                      : "Be the first to write a review"}
                  </span>
                </div>
                <div className="product_price">
                  <h2
                    className={
                      `${
                        props.discount
                          ? props.discount
                          : product.weekSale
                          ? disWeekSale
                          : product.discount
                      }` > 1
                        ? "dd"
                        : "none"
                    }
                  >
                    {`MRP. ${product.price}`}{" "}
                  </h2>{" "}
                  <span>
                    {" "}
                    {`${
                      props.discount
                        ? props.discount
                        : product.weekSale
                        ? disWeekSale
                        : product.discount
                    }` > 0
                      ? `Save Rs.${Math.floor(
                          (product.price / 100) *
                            `${
                              props.discount
                                ? props.discount
                                : product.weekSale
                                ? disWeekSale
                                : product.discount
                            }`
                        )}`
                      : ""}
                  </span>
                  <h1>
                    {" "}
                    {`${
                      props.discount
                        ? props.discount
                        : product.weekSale
                        ? disWeekSale
                        : product.discount
                    }` > 0
                      ? `Discounted Price Rs.${Math.floor(
                          product.price -
                            (product.price / 100) *
                              `${
                                props.discount
                                  ? props.discount
                                  : product.weekSale
                                  ? disWeekSale
                                  : product.discount
                              }`
                        )}`
                      : ""}
                  </h1>
                  <span>Inclusive of taxes.</span>
                </div>
                <div className="product_detail">
                  <h2>Description:</h2>
                  <p>{product.description}</p>
                  <h2>Specification:</h2>
                  <ul>
                    <li>
                      <Link to={`/collections/${product.printCollection}`}>
                        <strong>Collection:</strong> {product.printCollection}{" "}
                      </Link>
                    </li>
                    <li>
                      <strong>Colour:</strong> {product.colour}{" "}
                    </li>
                    <li>
                      <strong>External Material:</strong>{" "}
                      {product.externalMaterial}{" "}
                    </li>
                    <li>
                      <strong>Internal Material:</strong>{" "}
                      {product.internalMaterial}{" "}
                    </li>
                    <li>
                      <strong>Size:</strong> {product.size} inch
                    </li>
                  </ul>
                  <Link to="/usageandcare">
                    <span className="usageLink">
                      Know more about Usage and Care <ArrowForwardIcon />
                    </span>
                  </Link>
                  <ul>
                    <li>
                      Status :
                      <b
                        className={
                          product.stock < 1 ? "redColor" : "greenColor"
                        }
                      >
                        {product.stock < 1 ? "OutOfStock" : "InStock"}
                      </b>
                    </li>
                    <li>
                      <b
                        className={
                          product.stock < 5 ? "redColor" : "greenColor"
                        }
                      >
                        {product.stock < 5 && !product.stock === 0
                          ? "Hurry UP!! Only few pc left..!!"
                          : ""}
                        {product.stock === 0 ? "Will be in Stock Soon..!!" : ""}
                      </b>
                    </li>
                  </ul>
                </div>
                <div className="purchase_info">
                  <div className="quantity">
                    <button onClick={decreaseQuantity}>-</button>
                    <input
                      readOnly
                      value={product.stock < 1 ? 0 : quantity}
                      type="number"
                      min="0"
                    />
                    <button onClick={increaseQuantity}>+</button>
                  </div>
                  <div>
                    <button
                      disabled={product.stock < 1 ? true : false}
                      onClick={addToCartHandler}
                      type="button"
                      className="btn"
                    >
                      Add to Cart <ShoppingCartOutlinedIcon />
                    </button>
                    <button
                      disabled={product.stock < 1 ? true : false}
                      onClick={buyNowCartHandler}
                      type="button"
                      className="btn"
                    >
                      Buy Now <ShoppingCartOutlinedIcon />
                    </button>
                    <button
                      onClick={addToWishHandler}
                      type="button"
                      className="btn"
                    >
                      Wishlist <FavoriteBorderOutlinedIcon />
                    </button>
                    <button
                      onClick={submitReviewToggle}
                      type="button"
                      className="btn"
                    >
                      Submit Review <RateReviewOutlinedIcon />
                    </button>
                  </div>
                  <Dialog
                    aria-labelledby="simple-dialog-title"
                    open={open}
                    onClose={submitReviewToggle}
                  >
                    <DialogTitle>Submit Review</DialogTitle>
                    <DialogContent className="submitDialog">
                      <Rating
                        onChange={(e) => setRating(e.target.value)}
                        value={Number(rating)}
                        size="large"
                      />
                      <textarea
                        className="submitDialogTextArea"
                        cols="30"
                        rows="5"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                      ></textarea>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={submitReviewToggle} color="secondary">
                        Cancel
                      </Button>
                      <Button onClick={reviewSubmitHandler}>Submit</Button>
                    </DialogActions>
                  </Dialog>
                </div>

                <div className="social_links">
                  <p>Share At:</p>
                  <FacebookShareButton
                    url={shareLink}
                    quote={product.name}
                    hashtag={"#wocadesigns"}
                  >
                    <Link to="#">
                      <FacebookRoundedIcon fontSize="large" />
                    </Link>
                  </FacebookShareButton>
                  <Link to="#">
                    <InstagramIcon fontSize="large" />
                  </Link>
                  <PinterestShareButton
                    url={shareLink}
                    quote={product.name}
                    hashtag={"#wocadesigns"}
                    media={imgUrl}
                  >
                    <Link to="#">
                      <PinterestIcon fontSize="large" />
                    </Link>
                  </PinterestShareButton>
                  <WhatsappShareButton
                    url={shareLink}
                    quote={product.name}
                    hashtag={"#wocadesigns"}
                  >
                    <Link to="#">
                      <WhatsappOutlinedIcon fontSize="large" />
                    </Link>
                  </WhatsappShareButton>
                </div>
              </div>
            </div>
          </div>
          {/* Review section */}
          <h3 className="reviewsHeading">Reviews</h3>
          <Swiper
            slidesPerView={4}
            spaceBetween={10}
            pagination={{
              clickable: true,
              dynamicBullets: true,
            }}
            breakpoints={{
              "@0.00": {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              "@0.75": {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              "@1.00": {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              "@1.50": {
                slidesPerView: 4,
                spaceBetween: 10,
              },
            }}
            modules={[Pagination]}
            className="mySwiper"
          >
            {product.reviews && product.reviews[0] ? (
              <div className="reviews">
                {product.reviews &&
                  product.reviews.map((review) => (
                    <SwiperSlide key={review._id}>
                      <ReviewCard key={review._id} review={review} />
                    </SwiperSlide>
                  ))}
              </div>
            ) : (
              <div className="noReviews">
                Be the first to write a review <EditIcon />
              </div>
            )}
          </Swiper>
          {/* {product.category !== "Combo" &&
          product.category !== "Accessories" &&
          product.category !== "Hair-Accessory" ? (
            <div>
              <h3 className="reviewsHeading">Frequently Bought Together </h3>
              <div className="fproduct">
                <div className="PfreqproductConatiner">
                  <div className="freqproductConatiner">
                    {product.images && (
                      <img src={product.images[0].url} alt={product.name} />
                    )}
                    <h4 className="productNameH">{product.name}</h4>
                    <h4
                      className={
                        `${
                          props.discount
                            ? props.discount
                            : product.weekSale
                            ? disWeekSale
                            : product.discount
                        }` > 1
                          ? "dd"
                          : "none"
                      }
                    >
                      {`MRP. ${product.price}`}{" "}
                    </h4>{" "}
                    <h4>
                      {" "}
                      {`${
                        props.discount
                          ? props.discount
                          : product.weekSale
                          ? disWeekSale
                          : product.discount
                      }` > 0
                        ? ` Price Rs.${Math.floor(
                            product.price -
                              (product.price / 100) *
                                `${
                                  props.discount
                                    ? props.discount
                                    : product.weekSale
                                    ? disWeekSale
                                    : product.discount
                                }`
                          )}`
                        : ""}
                    </h4>
                  </div>
                  {freqProductOne && <h4 className="productNameH">+</h4>}
                  {freqProductOne && freqProductOne.images && (
                    <Link
                      className="freqLink"
                      to={`/product/${product.name}/${freqProductOne._id}`}
                    >
                      {" "}
                      <div className="freqproductConatiner">
                        <img
                          src={freqProductOne.images[0].url}
                          alt={freqProductOne.name}
                        />
                        <h4 className="productNameH">{freqProductOne.name}</h4>
                        <h4
                          className={
                            `${
                              props.discount
                                ? props.discount
                                : freqProductOne.weekSale
                                ? disWeekSale
                                : freqProductOne.discount
                            }` > 1
                              ? "dd"
                              : "none"
                          }
                        >
                          {`MRP. ${freqProductOne.price}`}{" "}
                        </h4>{" "}
                        <h4>
                          {" "}
                          {`${
                            props.discount
                              ? props.discount
                              : freqProductOne.weekSale
                              ? disWeekSale
                              : freqProductOne.discount
                          }` > 0
                            ? ` Price Rs.${Math.floor(
                                freqProductOne.price -
                                  (freqProductOne.price / 100) *
                                    `${
                                      props.discount
                                        ? props.discount
                                        : freqProductOne.weekSale
                                        ? disWeekSale
                                        : freqProductOne.discount
                                    }`
                              )}`
                            : ""}
                        </h4>
                      </div>
                    </Link>
                  )}
                  {freqProductTwo && <h4 className="productNameH">+</h4>}
                  {freqProductTwo && freqProductTwo.images && (
                    <Link
                      className="freqLink"
                      to={`/product/${product.name}/${freqProductTwo._id}`}
                    >
                      <div className="freqproductConatiner">
                        <img
                          src={freqProductTwo.images[0].url}
                          alt={freqProductTwo.name}
                        />
                        <h4 className="productNameH">{freqProductTwo.name}</h4>
                        <h4
                          className={
                            `${
                              props.discount
                                ? props.discount
                                : freqProductTwo.weekSale
                                ? disWeekSale
                                : freqProductTwo.discount
                            }` > 1
                              ? "dd"
                              : "none"
                          }
                        >
                          {`MRP. ${freqProductTwo.price}`}{" "}
                        </h4>{" "}
                        <h4>
                          {" "}
                          {`${
                            props.discount
                              ? props.discount
                              : freqProductTwo.weekSale
                              ? disWeekSale
                              : freqProductTwo.discount
                          }` > 0
                            ? ` Price Rs.${Math.floor(
                                freqProductTwo.price -
                                  (freqProductTwo.price / 100) *
                                    `${
                                      props.discount
                                        ? props.discount
                                        : freqProductTwo.weekSale
                                        ? disWeekSale
                                        : freqProductTwo.discount
                                    }`
                              )}`
                            : ""}
                        </h4>
                      </div>
                    </Link>
                  )}
                </div>
                <div className="calculation">
                  <div className="product_price ">
                    <h2 className="discH">
                      Price Rs.
                      {comboPrice}
                    </h2>
                    <h2>
              
                      Combo Just In Rs.
                      {Math.floor(comboPrice - (comboPrice * 0) / 100)}
                    </h2>
                    <h3>10% EXTRA DISCOUNT ON THE PURCHASE OF COMBO</h3>
                    <h4>Select the Combo Coupon at the Checkout Page</h4>
                    {product.stock === 0 ? (
                      "Will be Stock Soon!! Please have a look on other Combos"
                    ) : (
                      <button
                        disabled={product.stock < 1 ? true : false}
                        onClick={addFreqToCartHandler}
                        type="button"
                        className="Freqbtn"
                      >
                        Add to Cart <ShoppingCartOutlinedIcon />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )} */}
          <div>
            {similarProduct.length === 0 ? (
              ""
            ) : (
              <h3 className="reviewsHeading">Simliar Products </h3>
            )}

            <Swiper
              slidesPerView={4}
              spaceBetween={5}
              pagination={{
                clickable: true,
                dynamicBullets: true,
              }}
              breakpoints={{
                "@0.00": {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                "@0.75": {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                "@1.00": {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
                "@1.50": {
                  slidesPerView: 4,
                  spaceBetween: 10,
                },
              }}
              modules={[Pagination]}
              className="mySwiper"
            >
              <div className="productConatiner">
                {similarProduct &&
                  similarProduct.map((product) => (
                    <SwiperSlide key={product._id}>
                      <ProductCardHome key={product._id} product={product} />
                    </SwiperSlide>
                  ))}
              </div>
            </Swiper>
          </div>
          <div>
            {RecentProduct.length === 0 ? (
              ""
            ) : (
              <h3 className="reviewsHeading">Recently Viewed </h3>
            )}
            <Swiper
              slidesPerView={4}
              spaceBetween={5}
              pagination={{
                clickable: true,
                dynamicBullets: true,
              }}
              breakpoints={{
                "@0.00": {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                "@0.75": {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                "@1.00": {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
                "@1.50": {
                  slidesPerView: 4,
                  spaceBetween: 10,
                },
              }}
              modules={[Pagination]}
              className="mySwiper"
            >
              <div className="productConatiner">
                {RecentProduct.length === 8
                  ? RecentProduct.shift()
                  : RecentProduct.map((product) => (
                      <SwiperSlide key={product._id}>
                        <ProductCardRecent
                          key={product._id}
                          product={product}
                        />
                      </SwiperSlide>
                    )).reverse()}
              </div>
            </Swiper>
          </div>
        </>
      )}
    </>
  );
};

export default ProductDetails;
